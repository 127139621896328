export default class SelectAction {
  component!: any
  enabled: boolean = true
  meta: any

  setComponent(component: any): this {
    this.component = component
    return this
  }

  setEnabled(enabled: boolean): this {
    this.enabled = enabled
    return this
  }

  setMeta(meta: any): this {
    this.meta = meta
    return this
  }
}