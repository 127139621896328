import Model from '@/shared/classes/model'
import InstructionCategory from '@/shared/modules/instruction-categories/instruction-category.model'
import IProjectInstruction from '@/shared/modules/project-instructions/project-instruction-response.interface'
import User from '@/shared/modules/user/user.model'

export default class ProjectInstruction extends Model<IProjectInstruction> {
  id!: number
  name!: string
  content!: string
  instructionCategoryId!: number
  instructionCategoryName!: string
  instructionCategory!: InstructionCategory
  companyInstructionTemplateId!: number
  companyUuid!: string
  projectUuid!: string
  responsibleUuid!: string
  responsible!: User

  transform(data: IProjectInstruction): void {
    this.id = data.id
    this.name = data.name
    this.content = data.content
    this.instructionCategoryId = data.instruction_category_id
    this.companyInstructionTemplateId = data.company_instruction_template_id
    this.companyUuid = data.company_uuid
    this.projectUuid = data.project_uuid
    this.responsibleUuid = data.responsible_uuid
    this.instructionCategory = this.setRelation(InstructionCategory, data.instruction_category)
    this.responsible = this.setRelation(User, data.responsible)
    this.instructionCategoryName = this.instructionCategory.name
  }
}
