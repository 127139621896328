import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'

export default class EditorField extends Field {
  type: FieldTypes = FieldTypes.editorField
  imageUploadEndpoint: string | null = null

  setImageUploadEndpoint(imageUploadEndpoint: string | null): this {
    this.imageUploadEndpoint = imageUploadEndpoint
    return this
  }
}
