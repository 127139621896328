






import { Component, Prop, Vue } from 'vue-property-decorator'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import Model from '@/shared/classes/model'
import __ from '@/shared/helpers/__'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import ProjectInstructionService from '@/shared/modules/project-instructions/project-instruction.service'

@Component({
  methods: { __ },
})
export default class SelectedInstructionDelete extends Vue {
  @Prop() selected!: Array<Model<IModelResponse>>
  @Prop() meta!: any

  deleteSelected(): void {
    const selected = this.selected.map((item: any) => item.id)
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.components.views.projects.projects-show.assigned-instructions.bulk-delete-confirm.title'),
        text: __('company.components.views.projects.projects-show.assigned-instructions.bulk-delete-confirm.text'),
        onYes: () => ProjectInstructionService.bulkDelete(this.$route.params.company, this.$route.params.uuid, selected)
          .then(() => {
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('admin.views.instruction-categories.index.table.action.delete.on-success'),
            })
            this.meta.onSuccess && this.meta.onSuccess()
          }),
      },
    })
  }
}
