








import { Component, Prop, Vue } from 'vue-property-decorator'
import Project from '@/shared/modules/project/models/project.model'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import __ from '@/shared/helpers/__'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import ProjectInstruction from '@/shared/modules/project-instructions/project-instruction.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import can from '@/shared/helpers/can.helper'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import InstructionCreateDialog from '@/company/components/views/projects/projects-show/InstructionCreateDialog.vue'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import InstructionEditDialog from '@/company/components/views/projects/projects-show/InstructionEditDialog.vue'
import SelectAction from '@/shared/classes/components/data-table/data-table-select-actions'
import SelectedInstructionDelete from '@/company/components/views/projects/projects-show/SelectedInstructionDelete.vue'
import InstructionTemplate from '@/shared/modules/instruction-templates/instruction-template.model'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import InstructionTemplateService from '@/shared/modules/instruction-templates/instruction-template.service'
import ProjectInstructionService from '@/shared/modules/project-instructions/project-instruction.service'

@Component({
  methods: {
    can,
    __,
  },
  components: { DataTable },
})
export default class AssignedInstructions extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(ProjectInstruction)
      .setMultipleCheckbox(true)
      .setDoubleClick(this.openEditDialog)
      .setEndpoint(`company/${ this.company.slug }/projects/${ this.$route.params.uuid }/project-instructions`)
      .setGroupBy('instructionCategoryName')
      .setGroupDesc(false)
      .setGroupSelect(true)
      .setSelectActions([
        new SelectAction()
          .setComponent(SelectedInstructionDelete)
          .setEnabled(true)
          .setMeta({
            onSuccess: () => {
              this.tableRef.refresh()
            }
          })
      ])
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setText(__('company.views.projects.show.tabs.summary.assigned-instructions.table.columns.name')),
        new DataTableHeader()
          .setKey('responsible.fullName')
          .setSortable(false)
          .setText(__('company.views.projects.show.tabs.summary.assigned-instructions.table.columns.responsible')),
      ])
      .setActions([
        new EditAction(false)
          .setAction(this.openEditDialog),
        new DeleteAction().setAction(this.deleteInstruction),
      ])

    this.table.classes.tableCard = 'elevation-0'
    this.table.noPadding = true
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: InstructionCreateDialog,
      maxWidth: 1200,
      persistent: true,
      meta: {
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('company.components.project-instructions.create-dialog.on-success'),
          })
        },
      },
    })
  }

  openEditDialog(projectInstruction: ProjectInstruction): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: InstructionEditDialog,
      maxWidth: 1200,
      persistent: true,
      meta: {
        projectInstruction,
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('company.components.project-instructions.edit-dialog.on-success'),
          })
        },
      },
    })
  }

  deleteInstruction(projectInstruction: ProjectInstruction): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.projects.show.tabs.summary.assigned-instructions.table.actions.delete.title'),
        text: __('company.views.projects.show.tabs.summary.assigned-instructions.table.actions.delete.text', { name: projectInstruction.name }),
        onYes: () => ProjectInstructionService.destroy(this.company.slug, this.$route.params.uuid, projectInstruction.id)
          .then(() => {
            this.tableRef.refresh()
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.projects.show.tabs.summary.assigned-instructions.table.actions.delete.on-success'),
            })
          }),
      },
    })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
