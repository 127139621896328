















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Form from '@/shared/components/form/Form.vue'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import EditorField from '@/shared/classes/form/fields/editor-field'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import ProjectInstruction from '@/shared/modules/project-instructions/project-instruction.model'

@Component({
  components: { PageHeader, Form },
  methods: { __ },
})
export default class InstructionEditDialog extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setEndpoint(`company/${ this.company.slug }/projects/${ this.$route.params.uuid }/project-instructions`)
      .setMethod(HttpMethod.PUT)
      .setTranslatable(true)
      .setModel(ProjectInstruction)
      .setUuid(this.meta.projectInstruction.id as string)
      .setFields([
        new SearchableField()
          .setKey('instruction_category_id')
          .setEntryKey('instructionCategoryId')
          .setDense(true)
          .isRequired()
          .setTitle(__('company.components.project-instructions.edit-dialog.form.category'))
          .loadItems({ endpoint: '/instruction-categories', value: 'id', title: 'name', perPage: 20 })
          .setSize(FieldSizes.half),
        new SearchableField()
          .setKey('responsible_uuid')
          .setEntryKey('responsibleUuid')
          .setDense(true)
          .isRequired()
          .setTitle(__('company.components.project-instructions.edit-dialog.form.responsible'))
          .loadItems({
            endpoint: `company/${ this.company.slug }/employees`,
            value: 'uuid',
            title: 'full_name',
            perPage: 20,
          })
          .setSize(FieldSizes.half),
        new Field()
          .setKey('name')
          .isRequired()
          .setDense(true)
          .setTranslatable(true)
          .setSize(FieldSizes.half)
          .setTitle(__('company.components.project-instructions.edit-dialog.form.name')),
        new EditorField()
          .setKey('content')
          .setTranslatable(true)
          .isRequired()
          .setTitle(__('company.components.project-instructions.edit-dialog.form.content')),
      ])
      .setSubmit({
        text: __('company.components.project-instructions.edit-dialog.form.submit'),
      })
      .setInitialValues({
        is_visible: true,
      })
      .setValidate(true, { onlyRegionLangRequired: true })
      .setOnSuccess(this.meta.onSuccess)
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
