import Model from '@/shared/classes/model'
import IInstructionCategory from '@/shared/modules/instruction-categories/instruction-category-response.interface'

export default class InstructionCategory extends Model<IInstructionCategory> {
  id!: number
  name!: string

  transform(data: IInstructionCategory): void {
    this.id = data.id
    this.name = data.name
  }
}
