import Service from '@/shared/classes/service'
import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'

export default class ProjectInstructionService extends Service {
  static prefix = 'project-instructions'

  static destroy(companyUuid: string, projectUuid: string, id: number): Promise<any> {
    return this.delete(`company/${companyUuid}/projects/${projectUuid}/${ this.prefix }/${ id }`)
  }

  static show(id: number): Promise<any> {
    return this.get(`${ this.prefix }/${ id }`)
  }

  static bulkDelete(companyUuid: string, projectUuid: string, selected: Array<Model<IModelResponse>>) {
    return this.post(`company/${companyUuid}/projects/${projectUuid}/${ this.prefix }/bulk-delete`, { instructions: selected })
  }
}
